import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { graphql } from 'gatsby';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBody from '../components/PageBody';
import MainCol from '../components/PageBody/MainCol';
import JumbotronBlock from '../components/JumbotronBlock';
import SideRailsMenu from '../components/SideRailsMenu';

const ColumnWrapper = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-left: 1.5rem;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-top: 3.375rem;
  }

  > * {
    &:first-child {
      > * {
        margin-top: 0;

        > h2 {
          padding-top: 0;
        }
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function Page({ data, errors }) {
  const doc = data.prismic.allStandard_pages.edges.slice(0, 1).pop();
  if (!doc) {
    return null;
  }

  const docNode = doc.node;
  const {
    seo_title,
    seo_meta_description,
    page_title,
    page_description,
    header_banner,
    _meta,
    body,
    menu_link,
    menu_link_text,
  } = docNode;

  const pageName = page_title ? page_title[0].text : '';
  const pageDescription =
    page_description && page_description.length !== 0
      ? page_description[0].text
      : '';

  return (
    <Layout>
      <SEO
        title={seo_title || pageName}
        description={seo_meta_description || pageDescription}
        lang={_meta}
      />
      <JumbotronBlock
        title={pageName}
        url={header_banner && header_banner.url}
      />
      <Row>
        <SideRailsMenu title={pageName} data={body} />
        <MainCol lg={10} md={12} page={'true'}>
          <ColumnWrapper>
            {pageDescription && <p>{pageDescription}</p>}
            {body && <PageBody body={body} template={'page'} />}
          </ColumnWrapper>
        </MainCol>
      </Row>
    </Layout>
  );
}

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allStandard_pages(uid: $uid) {
        edges {
          node {
            seo_title
            seo_meta_description
            page_title
            page_description
            header_banner
            _meta {
              lang
            }
            body {
              ... on PRISMIC_Standard_pageBodyText_block {
                type
                primary {
                  content_collapse
                  text_block_header
                  tooltip
                }
                fields {
                  text_block_content
                }
              }
              ... on PRISMIC_Standard_pageBodyMenu_link {
                type
                primary {
                  menu_link_text
                  menu_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyTwo_columns {
                type
                fields {
                  text_block
                  title
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyTwo_columns_image {
                type
                fields {
                  image
                  title
                  text_block
                }
              }
              ... on PRISMIC_Standard_pageBodyCalendar_slice {
                type
                primary {
                  calendar_title
                  calendar_description
                  calendar_events {
                    ... on PRISMIC_Event {
                      events {
                        event_date
                        event_title
                        holiday
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyTable__10_cols_ {
                type
                primary {
                  table_title
                  table_description
                  tooltip
                  col1
                  col2
                  col3
                  col4
                  col5
                  col6
                  col7
                  col8
                  col9
                  col10
                  tooltip_col1
                  tooltip_col2
                }
                fields {
                  col1_data
                  col2_data
                  col3_data
                  col4_data
                  col5_data
                  col6_data
                  col7_data
                  col8_data
                  col9_data
                  col10_data
                }
              }
              ... on PRISMIC_Standard_pageBodyMailchimp_form {
                type
                primary {
                  mailchimp_form_id
                }
              }
              ... on PRISMIC_Standard_pageBodyCurrent_status {
                type
                primary {
                  button_text
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
